import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useQueries } from "react-query";
import {
  q_astokinfo,
  q_astokinfo_list,
  q_astokinfo_owner,
  q_vaultassets,
  q_vaultinfo,
  qiserr,
  qissuccesss,
} from "../queries/queries.js";
import { useParams } from "react-router";
import { dec, getv, jstr, nils } from "../utils/utils.js";
import { Helmet } from "react-helmet-async";
import { Card, Img, Tag } from "../components/utilityComps.js";
import { Loader01c } from "../components/anims.js";
import { twMerge } from "tailwind-merge";
import _ from "lodash";
import {
  ElementTag,
  FNoTag,
  SkinCard,
  TypeTag,
} from "../components/ShortComps";
import { Link } from "react-router-dom";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { useAppContext } from "../App.js";
import { asset_types_map } from "../contracts/contract_funcs.js";
const VaultContext = createContext({});
const useVaultContext = () => useContext(VaultContext);

export const AssetInfoImg = ({ asset_type, token_id, i = {} }) => {
  const override_img = i ? i.img : null;
  return (
    <>
      {!nils(override_img) && <Img img={override_img} />}
      {asset_type == "core" && (
        <>
          <video className="w-full h-full" autoPlay loop muted playsInline>
            <source
              src="https://api.dnaracing.run/fbike/static/animations/fbike.mp4"
              type="video/mp4"
            />
          </video>
        </>
      )}

      {asset_type == "skin" && (
        <>
          <SkinCard skin={i?.skin} rarity={i?.rarity} rvmode="bike" />
        </>
      )}
      {asset_type == "carskin" && (
        <>
          <SkinCard skin={i?.skin} rarity={i?.rarity} rvmode="car" />
        </>
      )}
      {asset_type == "god" && (
        <>
          <Img
            img={`https://api.dnaracing.run/fbike/static/images/godtoken.png`}
          />
        </>
      )}
      {asset_type == "corelootboxv2" && (
        <Img img={`https://i.imgur.com/5LJPt3M.png`} />
      )}
      {["skinlootboxv1", "skinlootboxv2", "skinlootboxv3"].includes(
        asset_type,
      ) && <Img img={`https://i.imgur.com/0qcVE6t.png`} />}

      {["skinlootbox_carweth"].includes(asset_type) && (
        <Img img={`https://i.imgur.com/BFbVsHa.png`} />
      )}
      {asset_type == "skinlootboxv101" && (
        <Img
          img={`https://static.vecteezy.com/system/resources/previews/009/597/575/original/halloween-pumpkin-ghost-png.png`}
        />
      )}
      {asset_type == "skingamelootbox" && !nils(token_id) && (
        <>
          <Img img={`https://www.dnaracing.run/imgs/sksc0${token_id}.png`} />
        </>
      )}
      {asset_type == "prizebox" && (
        <>
          <Img
            img={
              token_id == 1
                ? `https://i.imgur.com/6M8DdOR.png`
                : token_id == 2
                  ? `https://cdn.dnaracing.run/imgs/img_bigbox.png`
                  : null
            }
          />
        </>
      )}

      {asset_type == "tpgiftcard" && (
        <>
          <Img
            img={`https://cdn.dnaracing.run/imgs/tp_giftcard_${token_id}.png`}
          />
        </>
      )}
      {asset_type == "factionsagentsbox" && (
        <>
          <Img img={`https://i.imgur.com/e4SYcLa.png`} />
        </>
      )}
    </>
  );
};

export const AssetMiniCard = ({
  asset_type,
  erc,
  token_id,
  is_owner,
  vault = null,
}) => {
  // const vcon = useVaultContext();
  // const { vault } = vcon;
  const [qoastyinfo, qoastyowner, qoastylist] = useQueries([
    q_astokinfo({ asset_type, erc, token_id }),
    q_astokinfo_owner({ asset_type, token_id, sentby: vault }),
    q_astokinfo_list({ asset_type, token_id, vault }),
  ]);
  const i = useMemo(
    () => getv(qoastyinfo, "data.result", {}),
    [qoastyinfo.dataUpdatedAt],
  );
  const o = useMemo(
    () => getv(qoastyowner, "data.result", {}),
    [qoastyowner.dataUpdatedAt],
  );
  const list = useMemo(
    () => getv(qoastylist, "data.result", {}),
    [qoastylist.dataUpdatedAt],
  );
  const n = getv(o, "balance") ?? "-";

  const redlink =
    erc == 721
      ? `/asset/${asset_type}/${token_id}`
      : `/asset/${asset_type}/${token_id}?a=${vault}`;

  return (
    <Link target={"_blank"} to={redlink}>
      <Card className={"w-full h-full xs:p-0 lg:p-0"}>
        <div
          className={twMerge(
            " xs:w-[6rem] lg:w-[15rem] mx-auto rounded-lg overflow-hidden ",
            asset_type == "factionsagents"
              ? "lg:w-[10rem] aspect-[1/2]"
              : "aspect-[1/1]",
          )}
        >
          <AssetInfoImg {...{ token_id, asset_type, i }} />
        </div>

        <div className="resp-p-2 relative">
          <p className="resp-text--1 font-digi text-acc0">#{token_id}</p>
          {erc == 1155 && (
            <div className="absolute top-[-1rem] right-[1rem] fc-cc resp-text--1 p-2 rounded-full bg-acc0/40 text-white">
              <div className="fr-sc font-mon font-bold">
                <span className="resp-text--2">{"x"}</span>
                <span className="resp-text--1">{n}</span>
              </div>
            </div>
          )}

          {qissuccesss(qoastyinfo) && (
            <div className="resp-my-2">
              <p className="resp-text--2 font-digi text-acc0">{i.name}</p>

              {asset_type == "core" && (
                <>
                  <div className="fr-sc">
                    <div className="flex-1"></div>
                    <FNoTag className={" resp-text--3"} fno={i.fno} />
                    <ElementTag
                      className={" resp-text--4"}
                      element={i.element}
                    />
                    <TypeTag className={" resp-text--4"} type={i.type} />
                  </div>
                </>
              )}

              {asset_type == "skin" && (
                <>
                  <div className="fr-sc">
                    <div className="flex-1"></div>
                    <span className="text-yellow-400 resp-text--3">
                      {_.capitalize(i.rarity)}
                    </span>
                  </div>
                </>
              )}

              {asset_type == "factionsagents" && (
                <>
                  <div className="fr-sc resp-gap-2">
                    <div className="flex-1"></div>
                    <span className="text-yellow-200 resp-text--2">
                      {nils(i?.faction)
                        ? ""
                        : _.chain(i.faction)
                            .split("-")
                            .map(_.capitalize)
                            .join(" ")
                            .value()}
                    </span>

                    <div className="border border-yellow-200 text-yellow-200 resp-p-1 rounded-md -skew-x-12">
                      <span className="resp-text--2">
                        {nils(i?.rarity)
                          ? ""
                          : _.chain(i.rarity)
                              .split("-")
                              .map(_.capitalize)
                              .join(" ")
                              .value()}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {qissuccesss(qoastyowner) && (
            <div>
              <span className="resp-text--2">{o.owner_name}</span>
            </div>
          )}

          {qissuccesss(qoastylist) && (
            <div className="fc-ss">
              <div className="flex-1"></div>
              {!nils(list) && !_.isEmpty(list) ? (
                list?.map((l) => {
                  let l_txt = `${l.amt.toString()} ${l.token}`;
                  return (
                    <>
                      {is_owner ? (
                        <Tag className="text-acc0 resp-text--3">
                          {`Listed @ ${l_txt} `}
                        </Tag>
                      ) : (
                        <Tag className="bg-acc0/60 text-white resp-text--3">
                          {`Buy @ ${l_txt} `}
                        </Tag>
                      )}
                    </>
                  );
                })
              ) : (
                <>
                  {is_owner ? (
                    <></>
                  ) : (
                    <Tag className="border border-acc0/60 text-acc0/60 resp-text--3">
                      {"Bid Now"}
                    </Tag>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </Card>
    </Link>
  );
};

export const VaultPage = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const params = useParams();
  const vault = useMemo(() => {
    let v = getv(params, "vault");
    if (nils(v)) return null;
    return v.toLowerCase();
  }, [jstr(params)]);

  const aucon = useAuthContext();
  const is_owner = vault == aucon.vault;

  const [asset_type, set_asset_type] = useState(psearch.asset_type ?? "core");

  const [qovau, qovassets] = useQueries([
    q_vaultinfo({ vault }),
    q_vaultassets({ vault, asset_type }),
  ]);

  const vdoc = useMemo(
    () => getv(qovau, "data.result", {}),
    [qovau.dataUpdatedAt],
  );

  const vassets = useMemo(
    () => getv(qovassets, "data.result", {}),
    [qovassets.dataUpdatedAt],
  );

  const pagetitle = useMemo(() => {
    return `${vdoc?.vault_name} | Vault | DNA-Market`;
  }, [jstr(vdoc)]);

  useEffect(() => {
    upd_psearch({ asset_type });
  }, [asset_type]);

  const vcon = {
    vault,
    is_owner,
    vdoc,
    vassets,
    qovassets,
  };

  return (
    <VaultContext.Provider value={vcon}>
      <Helmet>
        <title>{pagetitle}</title>
      </Helmet>
      <div className="h-page">
        <div className="max-w-[98vw] w-[60rem] mx-auto">
          <div className="h-[2rem]"></div>
          {qovau.isLoading ? (
            <Loader01c />
          ) : qiserr(qovau) ? (
            <p className="text-center text-red-300">{qiserr(qovau)}</p>
          ) : qissuccesss(qovau) ? (
            <>
              <Card className={"w-full"}>
                <p className="resp-text--1 text-acc0">{vdoc?.vault_name}</p>
                <p className="resp-text--3">{vault}</p>
              </Card>

              <div className="grid xs:grid-cols-2 lg:grid-cols-3 resp-gap-2 flex-wrap border-b border-white my-2">
                {[
                  "core",
                  "skin",
                  "carskin",
                  "god",
                  "corelootboxv2",
                  "skinlootboxv2",
                  "skinlootboxv3",
                  "skinlootboxv101",
                  "skingamelootbox",
                  "skinlootbox_carweth",
                  "prizebox",
                  "dnaassets",
                  "factionsagents",
                  "factionsagentsbox",
                  "tpgiftcard",
                ].map((_k) => {
                  const asty = asset_types_map[_k];
                  if (nils(asty)) return <></>;

                  const active = asset_type == _k;
                  return (
                    <Tag
                      onClick={() => set_asset_type(_k)}
                      className={twMerge(
                        "font-digi resp-text--1 text-center",
                        active
                          ? "bg-acc0/40 -skew-x-12"
                          : "text-acc0 bg-acc0/0",
                      )}
                    >
                      {asty.name}
                    </Tag>
                  );
                })}
              </div>

              {qovassets.isLoading ? (
                <Loader01c />
              ) : nils(vassets) || _.isEmpty(vassets.tokenids) ? (
                <p className="text-center resp-text--1 text-yellow-300">
                  No Assets
                </p>
              ) : (
                <>
                  <div className="grid xs:grid-cols-2 lg:grid-cols-3 resp-gap-2">
                    {vassets.tokenids.map((a) => {
                      if (vassets.erc == 721)
                        return (
                          <AssetMiniCard
                            {...{
                              token_id: a,
                              asset_type: vassets.asset_type,
                              erc: vassets.erc,
                              is_owner,
                              vault,
                            }}
                          />
                        );
                      else if (vassets.erc == 1155)
                        return (
                          <AssetMiniCard
                            {...{
                              token_id: a[0],
                              n: a[1],
                              asset_type: vassets.asset_type,
                              erc: vassets.erc,
                              is_owner,
                              vault,
                            }}
                          />
                        );
                      else return <></>;
                    })}
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}

          <div className="h-[2rem]"></div>
        </div>
      </div>
    </VaultContext.Provider>
  );
};
